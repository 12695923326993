import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import NeushopService from "../../../api/services/neushopService";
import { getCurrencySymbol } from "../../../utils/getCurrencySymbol";
import { useVisible } from "../../../hooks/useVisible";
import { getTotalCost } from "../../../utils/getTotalCost";

const NeushopSlider = () => {
  const { getProductsList } = NeushopService();
  const [productsList, setProductsList] = useState(null);
  const MinimumAvailableStock = 1;
  const neushopRef = useRef();
  const { isVisible, observerRef } = useVisible(neushopRef);

  const hydrateCartDetails = async () => {
    try {
      const { result, data } = await getProductsList();
      if (!result) return;
      setProductsList(data);
    } catch (error) {}
  };

  useEffect(() => {
    if (!isVisible) return;
    hydrateCartDetails();
    observerRef.current.unobserve(neushopRef.current);
  }, [isVisible]);

  const settings = {
    autoplay: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <div className="tw-hidden"></div>,
    prevArrow: <div className="tw-hidden"></div>,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 440,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      <section className="blog-section section-b-space" ref={neushopRef}>
        {productsList?.length > 0 && (
          <div className="container-fluid-lg">
            <div className="title title-flex">
              <div>
                <h2 className="main-heading">NeuShop</h2>
                <h4 className="sub-main-heading">Best product at your place</h4>
              </div>
              <div className="timing-box">
                <Link to="/neu_shop">
                  <img
                    src="../assets/images/veg-3/category/live_right_arrow.png"
                    className="img-fluid slide-arrow-img lazyload"
                    alt=""
                    loading="lazy"
                  />
                </Link>
              </div>
            </div>

            <div className="row">
              <div className="col-xxl-12 col-xl-12 col-lg-12 order-lg-3 blog-slider">
                <Slider {...settings}>
                  {productsList.slice(0, 8).map((list) => {
                    return (
                      <Link
                        to={`neu_shop/item/${list._id}`}
                        className="tw-no-underline tw-text-black"
                        key={list._id}
                      >
                        <div className="item-car tw-relative hover:tw-shadow tw-shadow-box-light tw-w-full tw-h-full tw-cursor-pointer tw-box-border tw-overflow-hidden tw-rounded-md tw-bg-white">
                          <img
                            className="item-image hover:tw-scale-105 tw-text-black tw-border-b-gray-100 tw-border-solid tw-border-0 tw-border-b tw-inline-block tw-ease-out tw-duration-300 tw-w-full tw-h-[180px] sm:tw-h-[200px] lazyload"
                            src={list.image}
                            alt="product_pic"
                            loading="lazy"
                          />
                          <div className="tw-pb-4 tw-px-1.5 flex-column tw-gap-y-1.5 sm:tw-gap-y-2.5">
                            <h3 className="item-heading overflow-ellipsis sub-main-heading tw-text-black tw-font-medium sm:tw-text-xs md:tw-text-[15px]">
                              {list.productName}
                            </h3>

                            <div className="flex-item-between">
                              <div>
                                {list?.discountPercentage > 0 ? (
                                  <div className="flex-item-center tw-gap-x-2">
                                    <p className="tw-m-0 tw-text-sm sm:tw-text-base tw-text-slate-500 tw-line-through tw-decoration-reddish">
                                      {getTotalCost(list.price)}
                                    </p>
                                    <div className="flex-item-between tw-gap-x-2">
                                      <p className="item-price sub-main-heading tw-m-0">
                                        <span className="price-span tw-text-sm">
                                          {getCurrencySymbol(
                                            list.discountedPrice?.currency
                                          )}
                                        </span>
                                        {list.discountedPrice?.amount}
                                      </p>
                                    </div>
                                  </div>
                                ) : (
                                  <p className="item-price sub-main-heading tw-m-0">
                                    <span className="price-span tw-text-xs">
                                      {getCurrencySymbol(list?.price?.currency)}
                                    </span>
                                    {list?.price?.amount}
                                  </p>
                                )}
                              </div>
                              {list.productQuantity < MinimumAvailableStock ? (
                                <p className="tw-text-sm sm:tw-text-[15px] tw-m-0 tw-font-medium tw-text-red-600">
                                  OUT OF STOCK
                                </p>
                              ) : (
                                <div>
                                  <button className="add-to-cart-btn tw-shadow-card-shadow button tw-h-[30px] tw-min-w-[80px] tw-font-bold hover:tw-shadow-neu-theme-inner hover:tw-bg-light-theme tw-transition-all tw-duration-200 tw-ease-in-out">
                                    Buy
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                          {list?.discountPercentage > 0 && (
                            <div className="tw-absolute tw-right-0 tw-top-0">
                              <div className="tw-absolute tw-inline-block tw-bg-reddish tw-py-[6px] tw-bg-opacity-80 flex-item-center tw-rounded-bl-xl tw-px-2 tw-items-center tw-text-white tw-font-semibold tw-right-0 tw-min-w-[80px]">
                                <span className="tw-text-[10px] sm:tw-text-xs">
                                  ({list.discountPercentage} % off)
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                      </Link>
                    );
                  })}
                </Slider>
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default NeushopSlider;
