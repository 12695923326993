import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { hydrateCart } from "../../../../redux/slices/CartSlice";
import { toast } from "react-hot-toast";
import CartUtilityFunctions from "../../cartUtilities/cartUtilityFunctions";
import { getCurrencySymbol } from "../../../../utils/getCurrencySymbol";
import { getTotalCost } from "../../../../utils/getTotalCost";

const ProductCard = ({ cardDetails }) => {
  const {
    _id,
    image,
    productName,
    price,
    productQuantity,
    discountPercentage,
    discountedPrice,
  } = cardDetails;
  const cartItems = useSelector((store) => store.cart.items);
  const productDetail = cartItems.find((item) => item.productId === _id);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const MinimumAvailableStock = 1;
  const { addProductToCart, updateProductFromCart, removeProductFromCart } =
    CartUtilityFunctions();

  const addToCart = async (event) => {
    event.stopPropagation();
    const result = await addProductToCart(_id, 1);
    if (result) {
      dispatch(hydrateCart(result));
      toast.success("Item Added");
    }
  };

  const incrementProduct = async (event) => {
    event.stopPropagation();
    if (productDetail?.quantity >= productQuantity) {
      toast.error(`No more stock available!`);
      return;
    }
    const result = await updateProductFromCart(
      _id,
      productDetail?.quantity + 1
    );
    if (result) {
      dispatch(hydrateCart(result));
      toast.success("Item Added");
    }
  };

  const decrementProduct = async (event) => {
    event.stopPropagation();
    let result = null;
    if (productDetail.quantity === 1) {
      result = await removeProductFromCart(_id);
    } else {
      result = await updateProductFromCart(_id, productDetail?.quantity - 1);
    }

    if (result) {
      dispatch(hydrateCart(result));
      toast.error("Item Removed");
    }
  };

  const handleOnCardClick = () => {
    navigate(`/neu_shop/item/${_id}`);
  };

  return (
    <div
      onClick={handleOnCardClick}
      className="tw-w-full tw-h-full text-decoration-none"
    >
      <div className="item-car hover:tw-shadow-lg tw-shadow-card-shadow tw-w-full tw-h-full tw-cursor-pointer tw-box-border tw-overflow-hidden tw-rounded-md tw-bg-white">
        <img
          className="item-image tw-object-fill tw-aspect-square hover:tw-scale-105 tw-text-black tw-border-b-gray-100 tw-border-solid tw-border-0 tw-border-b tw-inline-block tw-ease-out tw-duration-300 tw-w-full tw-h-[180px]"
          src={image}
          loading="lazy"
          alt="product_pic"
        />
        <div className="tw-pb-4 tw-px-1.5 flex-column tw-gap-y-1.5 sm:tw-gap-y-2.5">
          <h3 className="item-heading sub-main-heading tw-text-black tw-font-medium sm:tw-text-xs md:tw-text-[15px] overflow-ellipsis">
            {productName}
          </h3>

          <div className="tw-flex tw-items-start tw-flex-col tw-gap-y-1">
            <div>
              {discountPercentage > 0 ? (
                <div className="flex-item-center tw-gap-x-2">
                  <p className="tw-m-0 tw-text-sm sm:tw-text-base tw-text-slate-500 tw-line-through tw-decoration-reddish">
                    {getTotalCost(price)}
                  </p>
                  <div className="flex-item-between tw-gap-x-2">
                    <p className="item-price sub-main-heading tw-m-0">
                      <span className="price-span tw-text-sm">
                        {getCurrencySymbol(discountedPrice?.currency)}
                      </span>
                      {discountedPrice?.amount}
                    </p>
                    <p className="tw-m-0 tw-text-purple tw-text-xs sm:tw-text-sm tw-font-semibold">
                      ({discountPercentage} % off)
                    </p>
                  </div>
                </div>
              ) : (
                <p className="item-price sub-main-heading tw-m-0">
                  <span className="price-span tw-text-xs">
                    {getCurrencySymbol(price?.currency)}
                  </span>
                  {price?.amount}
                </p>
              )}
            </div>
            {productQuantity < MinimumAvailableStock ? (
              <p className="tw-text-[16px] tw-font-medium tw-text-red-600 tw-m-0">
                OUT OF STOCK
              </p>
            ) : (
              <div className="tw-flex tw-w-full tw-justify-end tw-pt-1">
                {productDetail ? (
                  <div className="flex-item-center tw-gap-x-2">
                    <button
                      className="remove-to-cart-btn button hover:tw-opacity-70 tw-font-bold tw-px-4 py-2 flex-item-center tw-text-lg"
                      onClick={decrementProduct}
                    >
                      -
                    </button>
                    <span>{productDetail?.quantity}</span>
                    <button
                      className="remove-to-cart-btn button hover:tw-opacity-70 tw-font-bold tw-px-4 py-2 flex-item-center tw-text-lg"
                      onClick={incrementProduct}
                    >
                      +
                    </button>
                  </div>
                ) : (
                  <button
                    className="add-to-cart-btn tw-font-semibold button tw-min-w-[100px] tw-transition-all tw-duration-200 tw-ease-in-out hover:tw-shadow-neu-theme-inner hover:tw-bg-light-theme"
                    onClick={addToCart}
                  >
                    Add to Cart
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
