import useAxiosPrivateHook from "../../hooks/useAxiosPrivate";
import apiUrl from "../../pages/config";
import ApiRequestType from "../apiRequestType";
import ApiRequestor from "../apiRequestor";
import axios from "../axios";

const AstrologerService = () => {
  const requestor = new ApiRequestor(axios);
  const axiosPrivate = useAxiosPrivateHook();
  const authenticatedRequestor = new ApiRequestor(axiosPrivate);

  const astrologersGroupCount = async () => {
    const url = `${apiUrl}/astrologer/profile/customer/group_count`;
    const apiResponse = await requestor.makeRequest(ApiRequestType.GET, url);
    return apiResponse;
  };

  const getAstrologers = async (group = 1) => {
    const url = `${apiUrl}/astrologer/profile/customer?group=${group}`;
    const apiResponse = await requestor.makeRequest(ApiRequestType.GET, url);
    return apiResponse;
  };

  const getAstrologerDetails = async (astrologerId) => {
    const url = `${apiUrl}/astrologer/profile/customer/${astrologerId}`;
    const apiResponse = await authenticatedRequestor.makeRequest(
      ApiRequestType.GET,
      url
    );
    return apiResponse;
  };

  const getLiveAstrologers = async () => {
    const url = `${apiUrl}/live_streaming/`;
    const apiResponse = await requestor.makeRequest(ApiRequestType.GET, url);
    return apiResponse;
  };

  const joinLiveAstrologer = async (liveStreamingId) => {
    const url = `${apiUrl}/live_streaming/customer/join`;
    const reqData = { liveStreamingId };
    const apiResponse = await authenticatedRequestor.makeRequest(
      ApiRequestType.POST,
      url,
      reqData
    );
    return apiResponse;
  };

  const leaveLiveAstrologer = async (liveStreamingId) => {
    const url = `${apiUrl}/live_streaming/customer/leave`;
    const reqData = { liveStreamingId };
    const apiResponse = await authenticatedRequestor.makeRequest(
      ApiRequestType.POST,
      url,
      reqData
    );
    return apiResponse;
  };

  const getAstrologerAvailabilityList = async (astrologerId) => {
    const url = `${apiUrl}/user/astrologer_availability_list/${astrologerId}`;
    const apiResponse = await requestor.makeRequest(ApiRequestType.GET, url);
    return apiResponse;
  };

  const getUpcomingLiveAstrologers = async () => {
    const url = `${apiUrl}/live_streaming/upcoming`;
    const apiResponse = await requestor.makeRequest(ApiRequestType.GET, url);
    return apiResponse;
  };

  const getActiveAstrologers = async () => {
    const url = `${apiUrl}/astrologer/profile/customer/active`;
    const apiResponse = await requestor.makeRequest(ApiRequestType.GET, url);
    return apiResponse;
  };

  return {
    astrologersGroupCount,
    getAstrologers,
    getAstrologerDetails,
    getLiveAstrologers,
    getAstrologerAvailabilityList,
    joinLiveAstrologer,
    leaveLiveAstrologer,
    getUpcomingLiveAstrologers,
    getActiveAstrologers,
  };
};

export default AstrologerService;
