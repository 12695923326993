import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CustomerSupportService from "../api/services/customerSupportService";

const Footer = () => {
  const [customerSupportDetails, setCustomerSupportDetails] = useState({});
  const { getCustomerSupportNumber } = CustomerSupportService();

  useEffect(() => {
    fetchCustomerSupportDetails();
  }, []);

  const fetchCustomerSupportDetails = async () => {
    try {
      const { result, data } = await getCustomerSupportNumber();
      if (result) setCustomerSupportDetails(data);
    } catch (error) {}
  };

  const handleScrollClick = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  return (
    <>
      <div>
        <footer className="section-t-space footer-section-2 footer-color-3">
          <div className="container-fluid-lg">
            <div className="main-footer">
              <div className="row">
                <div className="col-lg-2 col-md-4 col-6">
                  <div className="footer-title">
                    <h4 className="text-white">For Astrologers</h4>
                  </div>
                  <ul className="footer-list footer-list-light footer-contact">
                    <li>
                      <Link to="/expert" target="_blank" className="light-text">
                        Astrologer Login
                      </Link>
                    </li>
                    <li>
                      <Link to="/expert" target="_blank" className="light-text">
                        Astrologer Registration
                      </Link>
                    </li>
                  </ul>

                  <div className="footer-title" style={{ paddingTop: "20px" }}>
                    <h4 className="text-white">Daily Muhurat</h4>
                  </div>
                  <ul className="footer-list footer-contact footer-list-light">
                    <li>
                      <Link
                        to="/panchang_page"
                        className="light-text"
                        onClick={handleScrollClick}
                      >
                        Choghadiya
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/muhurat"
                        className="light-text"
                        onClick={handleScrollClick}
                      >
                        Lagna
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/muhurat"
                        className="light-text"
                        onClick={handleScrollClick}
                      >
                        Hora
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-2 col-md-4 col-6">
                  <div className="footer-title">
                    <h4 className="text-white">Important Links</h4>
                  </div>
                  <ul className="footer-list footer-list-light footer-contact">
                    <li>
                      <Link
                        to="/kundali"
                        className="light-text"
                        onClick={handleScrollClick}
                      >
                        Kundli
                      </Link>
                    </li>
                    {/* <li>
                      <Link
                        to="/kundali"
                        className="light-text"
                        onClick={handleScrollClick}
                      >
                        Kundli PDF Download
                      </Link>
                    </li> */}
                    <li>
                      <Link
                        to="/kundali_milan"
                        className="light-text"
                        onClick={handleScrollClick}
                      >
                        Kundli Milan
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/panchang_page"
                        className="light-text"
                        onClick={handleScrollClick}
                      >
                        Panchang
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/astrologers_list"
                        className="light-text"
                        onClick={handleScrollClick}
                      >
                        Call Astrologer
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/astrologers_list"
                        className="light-text"
                        onClick={handleScrollClick}
                      >
                        Chat Astrologer
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/one_card"
                        className="light-text"
                        onClick={handleScrollClick}
                      >
                        Tarot
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/blog"
                        className="light-text"
                        onClick={handleScrollClick}
                      >
                        Blog
                      </Link>
                    </li>
                    {/* <li>
                      <Link
                        to="#"
                        className="light-text"
                        onClick={handleScrollClick}
                      >
                        All Festivals
                      </Link>
                    </li> */}
                    <li>
                      <Link
                        to="/numerology"
                        className="light-text"
                        onClick={handleScrollClick}
                      >
                        Numerology
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-2 col-md-4 col-6">
                  <div className="footer-title">
                    <h4 className="text-white">Shopping</h4>
                  </div>
                  <ul className="footer-list footer-contact footer-list-light">
                    {/* <li>
                      <Link
                        to="/online_puja"
                        className="light-text"
                        onClick={handleScrollClick}
                      >
                        Online Puja{" "}
                      </Link>
                    </li> */}
                    <li>
                      <Link
                        to="/offline_puja"
                        className="light-text"
                        onClick={handleScrollClick}
                      >
                        Puja Path
                      </Link>
                    </li>
                    <li>
                      <Link to="/neu_shop" className="light-text">
                        NeuShop
                      </Link>
                    </li>
                  </ul>

                  <div className="footer-title" style={{ paddingTop: "20px" }}>
                    <h4 className="text-white">Horoscope</h4>
                  </div>
                  <ul className="footer-list footer-list-light footer-contact">
                    <li>
                      <Link
                        to="/horoscope_daily"
                        className="light-text"
                        onClick={handleScrollClick}
                      >
                        Daily
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/horoscope_page"
                        className="light-text"
                        onClick={handleScrollClick}
                      >
                        Weekly
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/horoscope_monthly"
                        className="light-text"
                        onClick={handleScrollClick}
                      >
                        Monthly
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/horoscope_yearly"
                        className="light-text"
                        onClick={handleScrollClick}
                      >
                        Yearly
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-2 col-md-4 col-6">
                  <div className="footer-title">
                    <h4 className="text-white">Corporate Policy</h4>
                  </div>
                  <ul className="footer-list footer-contact footer-list-light">
                    <li>
                      <Link
                        to="/privacy_policy"
                        className="light-text"
                        onClick={handleScrollClick}
                      >
                        Privacy Policy
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/term_condition"
                        className="light-text"
                        onClick={handleScrollClick}
                      >
                        Terms Of Use
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/term_condition"
                        className="light-text"
                        onClick={handleScrollClick}
                      >
                        Refund & Cancellation
                      </Link>
                    </li>
                    {/* <li>
                      <Link
                        to="/Disclaimer"
                        className="light-text"
                        onClick={handleScrollClick}
                      >
                        Disclaimer
                      </Link>
                    </li> */}
                    <li>
                      <Link
                        to="/privacy_policy"
                        className="light-text"
                        onClick={handleScrollClick}
                      >
                        Cookies Policy
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/about_us"
                        className="light-text"
                        onClick={handleScrollClick}
                      >
                        About us
                      </Link>
                    </li>
                  </ul>
                  {/* <div className="footer-title" style={{ paddingTop: "20px" }}>
                    <h4 className="text-white">Useful Links</h4>
                  </div> */}
                  <ul className="footer-list footer-list-light footer-contact">
                    <li>
                      <Link to="/astrologers_list" className="light-text">
                        Best Astrologers
                      </Link>
                    </li>
                    {/* <li>
                      <Link to="#" className="light-text">
                        Secure Payments
                      </Link>
                    </li> */}
                    <li>
                      <Link
                        to="/privacy_policy"
                        className="light-text"
                        onClick={handleScrollClick}
                      >
                        Privacy & Confidentiality
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-3 col-md-4 col-12">
                  <a href="/" className="foot-logo theme-logo">
                    <div className="text-white footer-title">
                      <img
                        src="/assets/images/logo/footerLogo.png"
                        className="img-fluid blur-up lazyloaded web-logo-img lazyload "
                        alt="neuastro-logo"
                        loading="lazy"
                      />
                      NeuAstro
                    </div>
                  </a>
                  <ul className="social-icon">
                    <li className="light-bg">
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://www.facebook.com/profile.php?id=61561851071834&mibextid=ZbWKwL"
                        className="footer-link-color"
                        title="Visit NeuAstro Facebook Page"
                      >
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    {/* <li className="light-bg">
                      <a
                        href="https://accounts.google.com/signin/v2/identifier?flowName=GlifWebSignIn&amp;flowEntry=ServiceLogin"
                        className="footer-link-color"
                      >
                        <i className="fab fa-google"></i>
                      </a>
                    </li> */}
                    {/* <li className="light-bg">
                      <a
                        href="https://twitter.com/i/flow/login"
                        className="footer-link-color"
                      >
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li> */}
                    <li className="light-bg">
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://www.instagram.com/neuastro/"
                        className="footer-link-color"
                        title="Visit NeuAstro Instagram Page"
                      >
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                    <li className="light-bg">
                      <a
                        href="https://in.pinterest.com/NeuAstro/"
                        rel="noreferrer"
                        target="_blank"
                        className="footer-link-color"
                        title="Visit NeuAstro Pinterest Page"
                      >
                        <i className="fab fa-pinterest-p"></i>
                      </a>
                    </li>
                    <li className="light-bg">
                      <a
                        href="https://www.linkedin.com/company/neuastro/"
                        rel="noreferrer"
                        target="_blank"
                        className="footer-link-color"
                        title="Visit NeuAstro LinkedIn Page"
                      >
                        <i className="fa-brands fa-linkedin-in"></i>
                      </a>
                    </li>
                    <li className="light-bg">
                      <a
                        href="https://youtube.com/@neuastro?si=6U4ehV5oW2bVSLI-"
                        rel="noreferrer"
                        target="_blank"
                        className="footer-link-color"
                        title="Visit NeuAstro Youtube Channel"
                      >
                        <i className="fab fa-youtube"></i>
                      </a>
                    </li>
                    <li className="light-bg">
                      <a
                        href="https://x.com/NeuAstro"
                        rel="noreferrer"
                        target="_blank"
                        className="footer-link-color"
                        title="Visit NeuAstro Youtube Channel"
                      >
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                  </ul>
                  <div className="footer-title">
                    <h4 className="text-white">Contact Us</h4>
                  </div>
                  <ul className="footer-address footer-contact">
                    <li>
                      <div className="inform-box flex-start-box">
                        <i data-feather="mail"></i>
                        <span>Phone : {customerSupportDetails?.contactNo}</span>
                      </div>
                    </li>
                    <li>
                      <a
                        href="mailto:support@neuastro.com"
                        rel="noreferrer"
                        target="_blank"
                        className="light-text"
                      >
                        <div className="inform-box flex-start-box">
                          <i data-feather="mail"></i>
                          <span>Email : support@neuastro.com</span>
                        </div>
                      </a>
                    </li>
                    <li>
                      <div className="inform-box flex-start-box">
                        <i data-feather="mail"></i>
                        <span>
                          Office : 250A, Ellora Enclave, DayalBagh, Agra
                        </span>
                      </div>
                    </li>

                    {/* <li>
                      <a href="javascript:void(0)" className="light-text">
                        <div className="inform-box">
                          <i data-feather="phone"></i>
                            <span>Call us:- +91- 96503 39454</span>
                        </div>
                      </a>
                    </li> */}

                    {/* <!-- <li>
                                <a href="javascript:void(0)" className="light-text">
                                    <div className="inform-box">
                                        <i data-feather="mail"></i>
                                        <p>Email Us: Support@Fastkart.com</p>
                                    </div>
                                </a>
                           
                                <a href="javascript:void(0)" className="light-text">
                                    <div className="inform-box">
                                        <i data-feather="printer"></i>
                                        <p>Fax: 123456</p>
                                    </div>
                                </a>
                            </li> --> */}
                  </ul>
                  <div className="footer-title tw-pt-5 tw-mb-2.5">
                    <h4 className="text-white">Download Our App</h4>
                  </div>
                  <ul className="tw-flex tw-gap-2.5">
                    <li>
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://play.google.com/store/apps/details?id=com.neuastro.user_app"
                      >
                        <img
                          src="/assets/images/app/android1.png"
                          className="download-app-img blur-up lazyload"
                          alt="android_download"
                          loading="lazy"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://play.google.com/store/apps/details?id=com.neuastro.user_app"
                      >
                        <img
                          src="/assets/images/app/ios1.png"
                          className="download-app-img blur-up lazyload"
                          alt="ios_download"
                          loading="lazy"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="sub-footer sub-footer-lite  section-t-space">
            <div className="left-footer">
              <p className="light-text">
                2023 Copyright By Nakshtalk Solutions Pvt. Ltd.
              </p>
            </div>
          </div>
        </footer>
        {/* <!-- Footer Section End --> */}
      </div>
    </>
  );
};
export default Footer;
