import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LiveAstrologerCard from "./LiveAstrologerCard";
import OnlineAstrologerCard from "./OnlineAstrologerCard";
import AstrologerService from "../../../api/services/astrologerService";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import UpcomingLiveAstrologerCard from "./UpcomingLiveAstrologerCard";
import AuthContext from "../../../context/AuthProvider";
import { compareTwoValues } from "../../../constants/compareTwoValues";
import UserProfileService from "../../../api/services/userProfileService";
import { PromiseFulfilled } from "../../../constants/promiseFullfilled";
import { useDispatch } from "react-redux";
import { addGroupCount } from "../../../redux/slices/AstrologerSlice";

const ActiveAstrologerWrapper = () => {
  const {
    astrologersGroupCount,
    getLiveAstrologers,
    getUpcomingLiveAstrologers,
    getActiveAstrologers,
  } = AstrologerService();
  const { getUserProfile } = UserProfileService();
  const [astrologersList, setAstrologersList] = useState([]);
  const [liveAstrologerSessions, setLiveAstrologerSessions] = useState([]);
  const [upcomingLiveAstrologerList, setUpcomingLiveAstrologerList] = useState(
    []
  );
  const InitialLiveCount = 4;
  const { auth, setAuth } = useContext(AuthContext);
  const hasFreeCallChatProvision = auth?.userId
    ? auth.customerDetails?.hasFreeCallChatProvision
    : true;
  const dispatch = useDispatch();

  const getAstrologersGroupCount = async () => {
    try {
      const { result, data } = await astrologersGroupCount();
      if (!result) return;
      dispatch(addGroupCount(data));
    } catch (error) {}
  };

  const fetchAllAstrologersList = async () => {
    const promiseList = [getAstrologersGroupCount(), getActiveAstrologers()];
    if (auth?.userId && compareTwoValues(hasFreeCallChatProvision, true)) {
      promiseList.push(getUserProfile());
    }
    try {
      const [, activeAstrologersResponse, customerProfileResponse] =
        await Promise.allSettled(promiseList);

      if (
        activeAstrologersResponse.status === PromiseFulfilled &&
        activeAstrologersResponse.value.result
      ) {
        setAstrologersList(activeAstrologersResponse.value.data);
      }

      if (
        customerProfileResponse &&
        compareTwoValues(customerProfileResponse.status, PromiseFulfilled) &&
        customerProfileResponse.value.result
      ) {
        setAuth((previousValue) => ({
          ...previousValue,
          customerDetails: {
            hasFreeCallChatProvision:
              customerProfileResponse.value.data?.hasFreeCallChatProvision,
          },
        }));
      }
    } catch (error) {}
  };

  const fetchUpcomingLiveAstrologers = async () => {
    try {
      const { result, data } = await getUpcomingLiveAstrologers();
      if (!result) return;
      setUpcomingLiveAstrologerList(data);
    } catch (error) {}
  };

  const fetchLiveAstorgolersList = async () => {
    try {
      const { result, data } = await getLiveAstrologers();
      if (!result) {
        fetchUpcomingLiveAstrologers();
        return;
      }
      if (data.length < InitialLiveCount) {
        fetchUpcomingLiveAstrologers();
      }
      setLiveAstrologerSessions(data);
    } catch (error) {}
  };

  useEffect(() => {
    fetchLiveAstorgolersList();
    fetchAllAstrologersList();
  }, []);

  const settings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    dots: true,
    infinite: true,
    speed: 500,
    nextArrow: <div className="tw-hidden"></div>,
    prevArrow: <div className="tw-hidden"></div>,
    responsive: [
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplay: true,
          dots: true,
          infinite: true,
          speed: 500,
        },
      },
      {
        breakpoint: 440,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          dots: true,
          infinite: true,
          speed: 500,
        },
      },
    ],
  };

  return (
    <section className="order-detail">
      <div className="container-fluid-lg">
        <div className="row g-sm-4 g-3 tw-mb-3">
          <div className="col-xxl-12 col-xl-12 col-lg-12">
            <div className="title title-flex">
              <div>
                <h2 className="main-heading">Live Sessions</h2>
                <h3 className="sub-main-heading tw-text-grey">
                  {liveAstrologerSessions.length +
                    upcomingLiveAstrologerList.length >
                  0
                    ? "Astrologers are live now"
                    : "No Live and Upcoming sessions currently"}
                </h3>
              </div>
              <div className="timing-box">
                <Link to="/astrolive">
                  <img
                    src="../assets/images/veg-3/category/live_right_arrow.png"
                    className="img-fluid slide-arrow-img lazyload"
                    alt=""
                    loading="lazy"
                  />
                </Link>
              </div>
            </div>
            <div className="grid-4-cards tw-hidden min-[960px]:tw-grid">
              {liveAstrologerSessions
                ?.slice(0, InitialLiveCount)
                .map((astrologerData) => (
                  <LiveAstrologerCard
                    astrologerData={astrologerData}
                    key={astrologerData.astrologerDetails?.id}
                  />
                ))}

              {upcomingLiveAstrologerList
                ?.slice(0, InitialLiveCount - liveAstrologerSessions?.length)
                .map((astrologerDetail) => (
                  <UpcomingLiveAstrologerCard
                    astrologerDetail={astrologerDetail}
                    key={astrologerDetail._id}
                  />
                ))}
            </div>
            <div className="tw-w-full live-slider  min-[960px]:tw-hidden">
              {liveAstrologerSessions?.length +
                upcomingLiveAstrologerList?.length ===
              1 ? (
                <div className="grid-4-cards tw-grid">
                  {liveAstrologerSessions?.map((astrologerData) => (
                    <LiveAstrologerCard
                      astrologerData={astrologerData}
                      key={astrologerData.astrologerDetails?.id}
                    />
                  ))}
                  {upcomingLiveAstrologerList?.map((astrologerDetail) => (
                    <UpcomingLiveAstrologerCard
                      astrologerDetail={astrologerDetail}
                      key={astrologerDetail.astrologerDetails?.id}
                    />
                  ))}
                </div>
              ) : (
                <Slider {...settings}>
                  {liveAstrologerSessions
                    ?.slice(0, InitialLiveCount)
                    .map((astrologerData) => (
                      <div
                        className="py-1"
                        key={astrologerData.astrologerDetails?.id}
                      >
                        <LiveAstrologerCard astrologerData={astrologerData} />
                      </div>
                    ))}

                  {upcomingLiveAstrologerList
                    ?.slice(
                      0,
                      InitialLiveCount - liveAstrologerSessions?.length
                    )
                    .map((astrologerDetail) => (
                      <div key={astrologerDetail._id} className="py-1">
                        <UpcomingLiveAstrologerCard
                          astrologerDetail={astrologerDetail}
                        />
                      </div>
                    ))}
                </Slider>
              )}
            </div>
            <br />
          </div>
        </div>
        <div className="row g-sm-4 g-3">
          <div className="col-xxl-12 col-xl-12 col-lg-12">
            <div className="title title-flex">
              <div>
                <h2 className="main-heading">Online Astrologers</h2>
                <h3 className="sub-main-heading tw-text-grey">
                  Astrologers available for you
                </h3>
              </div>
              <div className="timing-box">
                <Link to="/astrologers_list">
                  <img
                    src="../assets/images/veg-3/category/live_right_arrow.png"
                    className="img-fluid slide-arrow-img lazyload"
                    alt=""
                    loading="lazy"
                  />
                </Link>
              </div>
            </div>
            <div className="grid-4-cards">
              {astrologersList?.slice(0, 8).map((astrologerDetails, index) => (
                <OnlineAstrologerCard
                  key={index}
                  astrologerDetails={astrologerDetails}
                  hasFreeCallChatProvision={hasFreeCallChatProvision}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ActiveAstrologerWrapper;
