import React, { memo, useState } from "react";
import NeushopService from "../../../../api/services/neushopService";
import Loader from "../../../../components/Loader";
import { ProductFilterType } from "../constants";

const Filter = memo(function Filter({
  setProductsList,
  setFilterValue,
  setPageFlag,
  pageRef,
  productFilterFlag,
  selectedCategory,
  setSelectedCategory,
  pageFlag,
}) {
  const [categoryList, setCategoryList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { getAllProductCategory, getProductsListWithCategoryFilter } =
    NeushopService();

  const handleOnClick = async () => {
    if (categoryList.length > 0) return;
    setIsLoading(true);
    try {
      const { result, data } = await getAllProductCategory();
      if (!result) return;
      setCategoryList(data);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleOnChange = async (event) => {
    pageRef.current = 1;
    const encodedValue = encodeURIComponent(event.target.value);
    setSelectedCategory(event.target.value);

    try {
      const { result, data } = await getProductsListWithCategoryFilter(
        pageRef.current,
        encodedValue
      );
      if (!result || !Array.isArray(data) || !data.length) {
        setPageFlag(false);
        setProductsList([]);
        return;
      }
      productFilterFlag.current = ProductFilterType.CATEGORY;
      setProductsList(data);
      setFilterValue(encodedValue);
      if (!pageFlag) setPageFlag(true);
    } catch (error) {}
  };

  return (
    <div className="tw-flex sm:tw-inline-block tw-justify-end">
      <label className="tw-mr-2" htmlFor="dropdown">
        {isLoading ? <Loader /> : "Category:"}
      </label>
      <select
        className="tw-cursor-pointer tw-border tw-border-gray-300 tw-rounded-md tw-px-2 tw-py-[6px]"
        name="dropdown"
        id="dropdown"
        onClick={handleOnClick}
        onChange={handleOnChange}
        value={selectedCategory}
      >
        <option selected disabled value="">
          --Select Category--
        </option>
        {categoryList.map((categoryData) => (
          <option key={categoryData._id} value={categoryData.category}>
            {categoryData.category}
          </option>
        ))}
      </select>
    </div>
  );
});

export default Filter;
