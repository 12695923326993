import React, { useContext, useEffect, useRef, useState } from "react";
import AstrologerService from "../../api/services/astrologerService";
import OnlineAstrologerCard from "../home/components/OnlineAstrologerCard";
import Breadcrumb from "../../components/Breadcrumb";
import { scrollToTop } from "../../utils/scrollToTop";
import Filters from "./components/Filters";
import CardShimmerUI from "../../components/CardShimmerUI";
import AuthContext from "../../context/AuthProvider";
import UserProfileService from "../../api/services/userProfileService";
import { compareTwoValues } from "../../constants/compareTwoValues";
import { PromiseFulfilled } from "../../constants/promiseFullfilled";
import { useSelector } from "react-redux";
import {
  generateAstrologerGroupSet,
  generateRandomAstrologerGroupNumber,
} from "../../utils/generateRandomAstrologerGroup";
import InfiniteScroll from "react-infinite-scroll-component";
import { SpinnerLoader } from "../../components/Loader";

function AstrologersList() {
  const { getAstrologers } = AstrologerService();
  const { getUserProfile } = UserProfileService();
  const [astrologersList, setAstrologersList] = useState([]);
  const [showList, setShowList] = useState(false);
  const [isFilterActive, setIsFilterActive] = useState(false);
  const { auth, setAuth } = useContext(AuthContext);
  const groupCount = useSelector((store) => store.astrologer.groupCount);
  const hasFreeCallChatProvision = auth?.userId
    ? auth.customerDetails?.hasFreeCallChatProvision
    : true;
  const astrologersGroupRef = useRef(generateAstrologerGroupSet(groupCount));
  const routes = [{ text: "Astrologer List" }];

  useEffect(() => {
    scrollToTop();
    postData();
  }, []);

  const generateRandomGroup = (totalGroup) => {
    const randomNumber = generateRandomAstrologerGroupNumber(totalGroup) - 1;
    const groupNumber = Array.from(astrologersGroupRef.current)[randomNumber];
    astrologersGroupRef.current.delete(groupNumber);
    return groupNumber;
  };

  const fetchAstrologersListByGroup = async () => {
    try {
      const { result, data } = await getAstrologers(
        generateRandomGroup(astrologersGroupRef.current.size)
      );
      if (!result) return;
      setAstrologersList([...astrologersList, ...data]);
    } catch (error) {}
  };

  const postData = async () => {
    if (Number(astrologersGroupRef.current.size) !== Number(groupCount)) {
      astrologersGroupRef.current = generateAstrologerGroupSet(groupCount);
    }
    const promiseList = [getAstrologers(generateRandomGroup(groupCount))];
    if (auth?.userId && compareTwoValues(hasFreeCallChatProvision, true)) {
      promiseList.push(getUserProfile());
    }
    try {
      setShowList(false);
      const [astrologersListResponse, customerProfileResponse] =
        await Promise.allSettled(promiseList);
      if (
        compareTwoValues(astrologersListResponse.status, PromiseFulfilled) &&
        astrologersListResponse.value.result
      ) {
        setAstrologersList(astrologersListResponse.value.data);
      }

      if (
        customerProfileResponse &&
        compareTwoValues(customerProfileResponse.status, PromiseFulfilled) &&
        customerProfileResponse.value.result
      ) {
        setAuth((previousValue) => ({
          ...previousValue,
          customerDetails: {
            hasFreeCallChatProvision:
              customerProfileResponse.value.data?.hasFreeCallChatProvision,
          },
        }));
      }
    } catch (error) {
    } finally {
      setShowList(true);
    }
  };

  return (
    <section className="section-b-space shop-section">
      <div className="container-fluid-lg">
        <div className="row">
          <div className="col-12">
            <div className="show-button">
              <div className="top-filter-menu-2 breadcrumb-container">
                <div>
                  <Breadcrumb routes={routes} />
                </div>
                <div>
                  <Filters
                    setAstrologersList={setAstrologersList}
                    setShowList={setShowList}
                    setIsFilterActive={setIsFilterActive}
                    postData={postData}
                  />
                </div>
              </div>
            </div>
            {showList ? (
              astrologersList.length > 0 ? (
                <InfiniteScroll
                  dataLength={astrologersList.length}
                  next={fetchAstrologersListByGroup}
                  style={{ overflow: "visible" }}
                  hasMore={
                    !isFilterActive && astrologersGroupRef.current.size !== 0
                  }
                  loader={
                    <div className="tw-h-[140px] tw-w-full flex-item-center">
                      <SpinnerLoader />
                    </div>
                  }
                >
                  <div className="grid-4-cards tw-mt-8 tw-mb-5">
                    {astrologersList.map((astrologerDetails, index) => (
                      <OnlineAstrologerCard
                        key={index}
                        astrologerDetails={astrologerDetails}
                        hasFreeCallChatProvision={hasFreeCallChatProvision}
                      />
                    ))}
                  </div>
                </InfiniteScroll>
              ) : (
                <div className="tw-h-[50vh] flex-item-center">
                  <h3 className="tw-text-center">No astrologer found!</h3>
                </div>
              )
            ) : (
              <CardShimmerUI />
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default AstrologersList;
