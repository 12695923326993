import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useEffect } from "react";
import { convertUTCToLocalDate } from "../../../utils/utcToLocalTimeConvertor";
import BlogService from "../../../api/services/blogService";
import { useVisible } from "../../../hooks/useVisible";

const LatestBlog = () => {
  const [latestBlogs, setLatestBlogs] = useState([]);
  const { getBlogsList } = BlogService();
  const blogRef = useRef();
  const { isVisible, observerRef } = useVisible(blogRef);

  useEffect(() => {
    if (!isVisible) return;
    fetchBlogsList();
    observerRef.current.unobserve(blogRef.current);
  }, [isVisible]);

  const fetchBlogsList = async () => {
    try {
      const { result, data, msg } = await getBlogsList();
      if (result) {
        setLatestBlogs(data);
      }
    } catch (error) {}
  };

  const settings = {
    autoplay: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <div className="tw-hidden"></div>,
    prevArrow: <div className="tw-hidden"></div>,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 440,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      <section className="blog-section section-b-space" ref={blogRef}>
        {latestBlogs.length > 0 && (
          <div className="container-fluid-lg">
            <div className="title title-flex">
              <div>
                <h2 className="main-heading">Blogs</h2>
                <h4 className="sub-main-heading">
                  Knowledge sharing for your benefit
                </h4>
              </div>
              <div className="timing-box">
                <Link to="/blog">
                  <img
                    src="../assets/images/veg-3/category/live_right_arrow.png"
                    className="img-fluid slide-arrow-img lazyload"
                    alt=""
                    loading="lazy"
                  />
                </Link>
              </div>
            </div>

            <div className="row">
              <div className="col-xxl-12 col-xl-12 col-lg-12 order-lg-3 blog-slider">
                <Slider {...settings}>
                  {latestBlogs.slice(0, 8).map((list, index) => {
                    return (
                      <Link
                        to={`/blog/${list._id}`}
                        className="tw-no-underline tw-text-black"
                        key={index}
                      >
                        <div className="blog-box wow fadeInUp">
                          <div className="blog-image">
                            <img
                              src={list.image}
                              className="bg-img blur-up lazyload img-fluid tw-w-full tw-h-[206px]"
                              alt={`blog_${list.title}`}
                              loading="lazy"
                            />
                          </div>

                          <div className="blog-contain tw-pl-1.5 tw-py-1.5">
                            <div className="blog-label tw-flex tw-flex-col tw-items-start tw-gap-y-2.5">
                              <h3 className="slider-card-heading tw-my-0 overflow-ellipsis">
                                {list.title}
                              </h3>
                              <div className="tw-text-para-color tw-flex tw-gap-x-1">
                                <span>{list?.author}</span>
                                <span>|</span>
                                <span>
                                  {convertUTCToLocalDate(list?.updatedAt)}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    );
                  })}
                </Slider>
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default LatestBlog;
