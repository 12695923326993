import useAxiosHook from "../../hooks/useAxios";
import useAxiosPrivateHook from "../../hooks/useAxiosPrivate";
import apiUrl from "../../pages/config";
import { ApplicationType, CustomerProfileType } from "../../pages/constants";
import ApiRequestType from "../apiRequestType";
import ApiRequestor from "../apiRequestor";

const UserProfileService = () => {
  const axiosPrivate = useAxiosPrivateHook();
  const requestor = new ApiRequestor(axiosPrivate);
  const { setAuthHeader } = useAxiosHook();

  const getUserProfile = async () => {
    const url = `${apiUrl}/user/profile/`;
    const apiResponse = await requestor.makeRequest(ApiRequestType.GET, url);
    return apiResponse;
  };

  const updateUserProfile = async (reqData) => {
    const url = `${apiUrl}/user/profile/`;
    const apiResponse = await requestor.makeRequest(
      ApiRequestType.PATCH,
      url,
      reqData
    );
    return apiResponse;
  };

  const updateNewUserDetails = async (reqData, authToken) => {
    const axios = setAuthHeader(authToken);
    const authenticatedRequestor = new ApiRequestor(axios);
    const url = `${apiUrl}/user/profile?requestingApplicationType=${ApplicationType.WEB}&customerProfileType=${CustomerProfileType.UNREGISTERED_CUSTOMER}`;
    const apiResponse = await authenticatedRequestor.makeRequest(
      ApiRequestType.PATCH,
      url,
      reqData
    );
    return apiResponse;
  };

  const uploadProfileImage = async (formData) => {
    const url = `${apiUrl}/user/profile/pic`;
    const apiResponse = await requestor.makeRequest(
      ApiRequestType.PUT_FILE,
      url,
      formData
    );
    return apiResponse;
  };

  const uploadCoverImage = async (formData) => {
    const url = `${apiUrl}/user/profile/cover_image`;
    const apiResponse = await requestor.makeRequest(
      ApiRequestType.PUT_FILE,
      url,
      formData
    );
    return apiResponse;
  };

  const deleteProfileAccount = async () => {
    const url = `${apiUrl}/user/profile`;
    const apiResponse = await requestor.makeRequest(ApiRequestType.POST, url);
    return apiResponse;
  };

  return {
    getUserProfile,
    updateUserProfile,
    updateNewUserDetails,
    uploadProfileImage,
    uploadCoverImage,
    deleteProfileAccount,
  };
};

export default UserProfileService;
