import React, { useContext, useEffect, useRef, useState } from "react";
import ProductCard from "./components/ProductCard";
import NeuShopHeader from "./components/NeuShopHeader";
import { scrollToTop } from "../../.././utils/scrollToTop";
import NeushopService from "../../../api/services/neushopService";
import { useDispatch, useSelector } from "react-redux";
import CartUtilityFunctions from "../cartUtilities/cartUtilityFunctions";
import { hydrateCart } from "../../../redux/slices/CartSlice";
import AuthContext from "../../../context/AuthProvider";
import InfiniteScroll from "react-infinite-scroll-component";
import { ProductFilterType } from "./constants";
import ProductShimmerUI from "./components/ProductShimmerUI";
import { SpinnerLoader } from "../../../components/Loader";

const NeuShopHome = () => {
  const [productsList, setProductsList] = useState(null);
  const [pageFlag, setPageFlag] = useState(true);
  const [filterValue, setFilterValue] = useState("");
  const cartItems = useSelector((store) => store.cart.items);
  const { fetchCartProducts } = CartUtilityFunctions();
  const {
    getProductsList,
    getProductsListWithSearchFilter,
    getProductsListWithCategoryFilter,
  } = NeushopService();
  const dispatch = useDispatch();
  const { auth } = useContext(AuthContext);
  const pageRef = useRef(1);
  const productFilterFlag = useRef(ProductFilterType.NOT_APPLIED);

  const fetchProductsList = async () => {
    pageRef.current = 1;
    productFilterFlag.current = ProductFilterType.NOT_APPLIED;
    try {
      const { result, data } = await getProductsList(pageRef.current);
      if (!result || !Array.isArray(data) || !data.length) {
        setPageFlag(false);
        return;
      }
      setProductsList(data);
      if (!pageFlag) setPageFlag(true);
    } catch (error) {}
  };

  const getProductApi = () => {
    switch (productFilterFlag.current) {
      case ProductFilterType.NOT_APPLIED:
        return getProductsList;
      case ProductFilterType.SEARCH:
        return getProductsListWithSearchFilter;
      case ProductFilterType.CATEGORY:
        return getProductsListWithCategoryFilter;
    }
  };

  const fetchMoreProducts = async () => {
    pageRef.current += 1;
    try {
      const { result, data } = await getProductApi()(
        pageRef.current,
        filterValue
      );
      if (!result || !Array.isArray(data) || !data.length) {
        setPageFlag(false);
        return;
      }
      setProductsList((previousValue) => [...previousValue, ...data]);
    } catch (error) {}
  };

  useEffect(() => {
    scrollToTop();
    fetchProductsList();
  }, []);

  useEffect(() => {
    if (!cartItems?.length && auth?.userId) {
      getAddedProducts();
    }
  }, [auth]);

  const getAddedProducts = async () => {
    const result = await fetchCartProducts();
    if (!result || !Array.isArray(result.products)) return;
    dispatch(hydrateCart(result));
  };

  return (
    <div className="container-fluid-lg">
      <div className="tw-bg-white flex-column tw-gap-y-10">
        <NeuShopHeader
          setProductsList={setProductsList}
          setFilterValue={setFilterValue}
          setPageFlag={setPageFlag}
          pageRef={pageRef}
          productFilterFlag={productFilterFlag}
          resetFilter={fetchProductsList}
          pageFlag={pageFlag}
        />
        <div>
          {productsList ? (
            productsList.length > 0 ? (
              <InfiniteScroll
                key={pageFlag}
                dataLength={productsList.length}
                next={fetchMoreProducts}
                style={{ overflow: "visible" }}
                hasMore={pageFlag}
                scrollThreshold="50%"
                loader={
                  <div className="tw-h-[140px] tw-w-full flex-item-center">
                    <SpinnerLoader />
                  </div>
                }
              >
                <div className="home-items tw-grid tw-grid-cols-1 min-[520px]:tw-grid-cols-2  md:tw-grid-cols-3 lg:tw-grid-cols-4 xl:tw-grid-cols-4 tw-px-4 sm:tw-px-8 tw-gap-x-5 tw-gap-y-8 tw-pb-12">
                  {productsList.map((productDetail) => (
                    <ProductCard
                      key={productDetail._id}
                      cardDetails={productDetail}
                    ></ProductCard>
                  ))}
                </div>
              </InfiniteScroll>
            ) : (
              <div className="tw-h-[70vh] flex-item-center">
                <h3>No product is available</h3>
              </div>
            )
          ) : (
            <ProductShimmerUI />
          )}
        </div>
      </div>
    </div>
  );
};

export default NeuShopHome;
