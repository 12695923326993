import React, { memo } from "react";
import NeushopService from "../../../../api/services/neushopService";
import { ProductFilterType } from "../constants";
import useDebounce from "../../../../hooks/useDebounce";

const SearchBar = memo(function SearchBar({
  setProductsList,
  setFilterValue,
  setPageFlag,
  pageRef,
  productFilterFlag,
  searchQuery,
  setSearchQuery,
  pageFlag,
}) {
  const { getProductsListWithSearchFilter } = NeushopService();
  useDebounce(fetchProductList, 250, searchQuery);

  async function fetchProductList(productName) {
    pageRef.current = 1;
    const encodedValue = encodeURIComponent(productName?.trim());
    try {
      const { result, data } = await getProductsListWithSearchFilter(
        pageRef.current,
        encodedValue
      );
      if (!result || !Array.isArray(data) || !data.length) {
        setPageFlag(false);
        setProductsList([]);
        setSearchQuery(null);
        return;
      }
      productFilterFlag.current = ProductFilterType.SEARCH;
      setProductsList(data);
      setFilterValue(encodedValue);
      if (!pageFlag) setPageFlag(true);
      if (!searchQuery) setSearchQuery(null);
    } catch (error) {}
  }

  const handleOnChange = async (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <div className="tw-mb-1 flex-item-center tw-overflow-hidden tw-border tw-border-solid tw-border-gray-400 tw-rounded-lg md:tw-w-2/3 lg:tw-w-1/2 flex-item-between">
      <input
        type="text"
        placeholder="Search Products"
        className="tw-w-full tw-text-gray-700 tw-px-2 tw-border-none tw-outline-none tw-placeholder-slate-400 tw-font-medium tw-rounded-md tw-text-[15px] tw-py-[6px]"
        onChange={handleOnChange}
        value={searchQuery}
      />
    </div>
  );
});

export default SearchBar;
